import React from 'react'
import no_profile_placeholder from '../images/no-profile.svg'
import Lightbox from 'react-image-lightbox'

export default class EachMatch extends React.Component {
  constructor() {
    super()
    this.state = {
      liked: false,
      popImage: false,
    }
  }

  liked = () => {
    this.setState({
      liked: true
    })
    this.props.like(this.props.user)
  }

  disliked = () => {
    this.setState({
      liked: false
    })
    this.props.dislike(this.props.user)
  }

  render() {
    const user = this.props.user
    return (
      <tr style={{ lineHeight: '5' }}>
        <td className="profile">
          <img
            style={{ marginLeft: '-30px' }}
            className="match-profile mobimg deskimg"
            src={user.photo_url ? user.photo_url : no_profile_placeholder}
            onClick={() => this.setState({ popImage: true })}
          />
          {this.state.popImage && (
            <Lightbox
              mainSrc={user.photo_url ? user.photo_url : no_profile_placeholder}
              onCloseRequest={() => {
                this.setState({ popImage: false })
              }}
            />
          )}
        </td>
        <td style={{ whiteSpace: 'nowrap', width: '60%' }}>
          <p className="title is-6">
            {user.first_name}
          </p>
        </td>
        <td className="fd-center">
          <div className={this.state.liked ? '' : 'displaynone'}>
            <svg
                onClick={this.disliked}
                className="mobbutts"
                style={{ fill: 'red' }}
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
            >
              <path d="M12 4.419c-2.826-5.695-11.999-4.064-11.999 3.27 0 7.27 9.903 10.938 11.999 15.311 2.096-4.373 12-8.041 12-15.311 0-7.327-9.17-8.972-12-3.27z" />
            </svg>
          </div>
          <div className={this.state.liked ? 'displaynone' : ''}>
            <svg
                onClick={this.liked}
                className="mobbutts"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40.508 40.508"
            >
              <path d="M3.023,20.822c0.019,0.031,0.042,0.059,0.066,0.083l15.918,16.914 c0.115,0.122,0.271,0.192,0.444,0.197h0.012c0.163,0,0.317-0.062,0.435-0.176L37.28,21.032c0.008-0.006,0.076-0.068,0.117-0.108 c0.034-0.032,0.062-0.065,0.068-0.081c1.962-2.032,3.043-4.702,3.043-7.517c0-5.974-4.86-10.834-10.835-10.834 c-3.91,0-7.498,2.094-9.419,5.48c-1.92-3.387-5.508-5.48-9.419-5.48C4.86,2.492,0,7.352,0,13.326 C-0.001,16.14,1.078,18.808,3.023,20.822z M10.834,3.743c3.875,0,7.346,2.312,8.842,5.891c0.098,0.233,0.324,0.383,0.577,0.383 c0.252,0,0.479-0.15,0.577-0.384c1.497-3.578,4.968-5.89,8.843-5.89c5.285,0,9.585,4.3,9.585,9.584 c0,2.521-0.978,4.904-2.754,6.712c-0.017,0.018-0.032,0.035-0.045,0.053L19.483,36.5l-15.4-16.358 c-0.023-0.037-0.05-0.072-0.082-0.104c-1.775-1.805-2.752-4.188-2.752-6.711C1.249,8.042,5.549,3.743,10.834,3.743z"/>
            </svg>
          </div>
        </td>
      </tr>
    )
  }
}
