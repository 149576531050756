import React from 'react'
import Layout from '../components/layout'

import EventMatching from '../components/event-matching'

const EventMatchingPage = () => (
    <Layout>
        <EventMatching />
    </Layout>
)

export default EventMatchingPage