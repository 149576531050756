import React from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'
import EachMatch from './each-match'
import * as storage from '../utils/storage'

export default class EventMatching extends React.Component {
  state = {
    users: [],
    decidedUsers: [],
    event: {},
    eventId: null,
    decisions: []
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const eventId = urlParams.get('id')
    this.setState({ eventId })

    axios.post(`${process.env.API_BASE_URL}/graphql`, {
      query: `query {
        event(id: ${eventId}) {
          id
          name
          status
          decidedUsers {
            from_user_id
            to_user_id
          }
          attendees {
            id
            email
            first_name
            last_name
            age
            gender
            mobile
            location
            photo_url
            public_contacts
          }
        }
      }`
    })
    .then(res => {
      this.setState({
        event: res.data.data.event,
        users: res.data.data.event.attendees,
        decidedUsers: res.data.data.event.decidedUsers
      })

      let decisions = this.state.decisions
      let currentUser = storage.getItem('user')
      currentUser = currentUser ? JSON.parse(currentUser) : currentUser
      const decided = this.state.decidedUsers.filter(item => item.from_user_id === currentUser.id).map(item => item.to_user_id)

      this.state.users.map((user) => {
          if (currentUser && user.email !== currentUser.email && decided.indexOf(user.id) === -1 && user.gender !== currentUser.gender) {
              decisions.push({
                  user_id: user.id,
                  event_id: eventId,
                  decision: 'none'
              })
          }
      })
    })
    .catch(err => console.log(err))
  } // componentDidMount

  like = (user) => {
      this.toggleLike(user, 'like')
  }

  dislike = (user) => {
      this.toggleLike(user, 'none')
  }

  toggleLike = (user, like) => {
      this.setState({
          decisions: this.state.decisions.map((item) => {
              if (user.id === item.user_id) {
                  item.decision = like
              }
              return item
          })
      })
  }

  handleDoneMatching(e) {
    e.preventDefault()
    const confirm = window.confirm("Are you sure you have finished selecting all of your matches for this event?")
    if (confirm) {
      const eventId = this.state.eventId
      axios.post(`${process.env.API_BASE_URL}/api/events/${eventId}/done-matching`, {
          decisions: this.state.decisions
      }, {
        headers: {
          'Authorization': 'Bearer ' + storage.getItem('access_token'),
        }
      })
        .then(res => navigate('/events'))
        .catch(err => console.log(err))
    }
  }

  render() {
    const eventId = this.state.eventId
    let currentUser = storage.getItem('user')
    currentUser = currentUser ? JSON.parse(currentUser) : currentUser
    const decided = this.state.decidedUsers.filter(item => item.from_user_id === currentUser.id).map(item => item.to_user_id)

    // console.log(currentUser.gender);
    return (
      <div className="container column is-half">
        <h2 className="has-text-centered title is-2">Select Your Matches</h2>
        <center>
            <p>Choose who you would like to match with by selecting the &#10084; next to their name.</p>
            <p>You can also tap on a photo to enlarge it.</p>
            <p>Select the Done Matching button to submit your matches.</p>
        </center>
        <h3 className="has-text-centered title is-4">{this.state.event.name}</h3>
        <table className="table matching"  style={{marginBottom:'50px', tableLayout: 'fixed', width: '100%', whiteSpace: 'nowrap'}}>
            <thead>
                <tr>
                    <th style={{width:'20%', borderWidth: '0 0 0px'}}></th>
                    <th style={{width:'30%', borderWidth: '0 0 0px'}}></th>
                    <th style={{width:'50%', borderWidth: '0 0 0px'}}></th>
                </tr>
            </thead>
            <tbody>
                {this.state.users.map((user, i) => {
                  if (currentUser && user.email !== currentUser.email && decided.indexOf(user.id) === -1 && user.gender !== currentUser.gender) {
                    return <EachMatch key={i} user={user} eventId={eventId} like={this.like} dislike={this.dislike}></EachMatch>
                  }
                })}
            </tbody>
        </table>
        <div className="has-text-centered">
          <button className="button is-danger" onClick={e => this.handleDoneMatching(e)}>Done Matching</button>
        </div>
      </div>
    )
  }
}
